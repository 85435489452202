@import-normalize;

@font-face {
  font-family: 'Raleway';
  src: url("assets/fonts/Raleway-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: 'Raleway';
  src: url("assets/fonts/Raleway-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: 'Raleway';
  src: url("assets/fonts/Raleway-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: 'Raleway';
  src: url("assets/fonts/Raleway-ExtraBold.ttf");
  font-weight: 800;
}

body {
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: fit-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
